/*
font-family: var(--font-family);
*/




/*
@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
*/



:root {
    --color-shadow0: hsla(0, 0%, 0%, 0.75);
    --color-shadow1: hsla(0, 0%, 12%, 0.75);
    --color-shadow2: hsla(0, 0%, 18%, 0.75);
    --color-shadow3: hsla(0, 0%, 24%, 0.75);


    --color-dark-shadow: hsla(0, 0%, 0%, 0.5);
    --color-dark0: hsl(0, 0%, 12%);
    --color-dark1: hsl(0, 0%, 18%);
    --color-dark2: hsl(0, 0%, 25%);
    --color-dark3: hsl(0, 0%, 30%);

    --color-dark0-transparent: hsla(0, 0%, 12%, 0.5);
    --color-dark1-transparent: hsla(0, 0%, 18%, 0.5);
    --color-dark2-transparent: hsla(0, 0%, 25%, 0.5);
    --color-dark3-transparent: hsla(0, 0%, 30%, 0.5);

    --color-dark-gray-tint: hsl(220, 13%, 18%);
    --color-dark-tint: hsl(220, 4%, 15%);
    --color-gray-tint: hsla(210, 8%, 34%, 0.8);
    --color-gray-tint1: hsla(207, 10%, 17%, 0.75);

    --color-middle-gray: hsl(0, 0%, 40%);



    --color-light0: hsl(0, 0%, 88%);
    --color-light1: hsl(0, 0%, 76%);
    --color-light2: hsl(0, 0%, 64%);
    --color-light3: hsl(0, 0%, 52%);
    --color-light-gray-tint: hsl(221, 18%, 61%);


    --color-text: hsl(0, 0%, 88%);
    --color-text-secondary: hsl(0, 0%, 76%);

    --color-warning: hsl(36, 100%, 50%);
    --color-warning-secondary: hsl(48, 100%, 50%);
    --color-warning-tertiary: hsl(40, 100%, 65%);

    --color-main-theme: hsl(211, 35%, 50%);
    --color-main-theme-light: hsl(211, 100%, 75%);
    --color-main-theme-light-transparent: hsla(211, 100%, 75%, 0.75);
    --color-main-theme-dark-desaturated: hsl(216, 25%, 33%);
    --color-main-theme-darker-desaturated: hsl(216, 25%, 17%);

    --color-main-theme-alpha: hsla(213, 76%, 45%, 0.25);

    --color-main-theme-dark: hsl(216, 45%, 34%);
    --color-main-theme-dark-transparent: hsla(216, 45%, 34%, 0.5);
    --color-main-theme-darker: hsl(216, 45%, 25%);
    --color-main-theme-darkest: hsl(224, 90%, 16%);
    --color-main-theme-darkest-transparent: hsla(224, 90%, 16%, 0.5);
    --color-main-theme-saturated: hsl(207, 100%, 50%);

    --color-error: hsla(0, 100%, 44%, 0.7);
    --color-error-dark: hsl(0, 100%, 27%);
    --color-error-light: hsl(0, 100%, 50%);
    --color-success: hsl(120, 99%, 37%);
    --color-success-dark: hsl(120, 100%, 22%);
    --color-success-light: hsl(120, 100%, 44%);

    --background-gradient-a: linear-gradient(to bottom, var(--color-main-theme-dark), var(--color-main-theme-darker));
    --background-gradient-b: linear-gradient(to right, var(--color-dark-gray-tint), var(--color-dark0));
    --background-gradient-c: linear-gradient(to bottom, var(--color-main-theme-dark-desaturated), var(--color-main-theme-darker-desaturated));
    --background-gradient-d: linear-gradient(to bottom, var(--color-dark-gray-tint), var(--color-dark0) 125%);
    --background-gradient-glow: linear-gradient(to bottom, var(--color-main-theme-dark), var(--color-main-theme-saturated));

    --background-gradient-tableA: linear-gradient(to right, hsl(210, 6%, 16%), hsl(0, 0%, 19%));
    --background-gradient-tableB: linear-gradient(to left, hsl(240, 6%, 24%), hsl(225, 11%, 22%));


    --button-hover-glow: hsl(211, 100%, 75%);
    --button-hover-glow-transparent1: hsla(211, 100%, 75%, 0.75);
    --button-hover-glow-transparent2: hsla(211, 100%, 75%, 0.5);
    --button-hover-glow-transparent3: hsla(211, 100%, 75%, 0.25);
    --button-hover-glow-gradient: linear-gradient(to bottom, hsla(215, 43%, 35%, 0.8), hsla(213, 100%, 63%, 0.78));



}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}



*::-webkit-scrollbar {

    width: 0.75rem;
    background: var(--color-dark0); /* #1e1e1e */

}

::-webkit-scrollbar-track {

    background: var(--color-dark-gray-tint); /* #282c34 */
}

*::-webkit-scrollbar-thumb {

    background: var(--color-dark0); /* 1e1e1e */
    background: linear-gradient(270deg, rgb(55, 55, 55), rgb(100, 100, 100))
}

::-webkit-scrollbar-thumb:horizontal {
    background: linear-gradient(0deg, rgb(55, 55, 55), rgb(100, 100, 100));

}

*::-webkit-scrollbar:horizontal {
    height: 0.75rem;

}

input[type="number"]::-webkit-inner-spin-button {
    display: none;

}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

body {
    scrollbar-width: 15;
    background-color: #0e0e10;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    color: var(--color-text); /* e1e1e1 */
    -webkit-font-smoothing: antialiased;
    height: 100dvh;
}


html {
    font-size: clamp(12px, 1.5vw, 16px);
}

iframe {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.Loginheader {
    width: 100%;
    height: 80px;
    background: linear-gradient(to top, rgb(11, 18, 30) -20%, rgb(4, 69, 139) 15%, rgb(49, 80, 125) 160%);
    display: block;
}

.App-main {
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    justify-items: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    background-color: rgb(11, 11, 19);

}

.LoginForm {
    margin: 120px;
    background-color: #282c34;
    flex: 0 0 auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-content: center;

    padding: 50px;
    border-radius: 25px;
    border: 2px solid rgba(4, 102, 231, 0.658);
}

.LoginSubmitButton {
    flex: 1 0 auto;
    padding: 10px 0px 20px 0px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    /* align-content: center; */
    justify-content: center;
    align-items: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}




.DataGrid {
    height: 100%;
    width: 100%;
}

.dashboardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    margin: 30px 50px 0px 50px;
}

.counterBox {
    padding: 10px;
    background: rgba(99, 99, 99, 0.75) !important;
    flex-grow: 1;
    height: 50px;
    width: 80px !important;
    margin: 0px 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: 10px;
    box-shadow: inset rgb(48 48 48 / 40%) 0px 0px 24px 12px;
}

.counterBox:hover {

    filter: saturate(1.25) contrast(1.25) brightness(1.3) !important;
    transition: all 0.3s ease-in !important;
    outline: 1px solid white !important;
}

.counterBoxActive {
    background: rgb(5 177 85 / 66%) !important;
    filter: saturate(1.25) contrast(1.75) !important;
    transition: all 0.3s ease-in !important;
    outline: 1px solid rgb(4 226 4 / 66%) !important;
}

.counterBoxActive:hover {
    background: rgb(4 167 20 / 85%) !important;
    box-shadow: inset rgb(48 48 48 / 45%) 0px 0px 24px 12px !important;
    filter: contrast(1.35) !important;
    transition: all 0.3s ease-in !important;
    outline: 1px solid rgba(95, 228, 83, 0.8) !important;
}

.counterBoxActiveMissing {
    background: rgb(212 170 18 / 70%) !important;
    filter: saturate(1.25) contrast(1.75) !important;
    transition: all 0.3s ease-in !important;
    outline: 1px solid rgb(226 172 4 / 66%) !important;
}

.counterBoxActiveMissing:hover {
    background: rgb(212 170 18 / 85%) !important;
    box-shadow: inset rgb(48 48 48 / 45%) 0px 0px 24px 12px !important;
    filter: contrast(1.35) !important;
    transition: all 0.3s ease-in !important;
    outline: 1px solid rgba(226, 172, 4, 0.8) !important;
}



.counterTextData {
    padding: 2px;
    font-size: 1rem;
    font-weight: bolder;
    flex-grow: 1;
}

.counterTextBox {
    display: flex;
    width: auto;

    flex-direction: column;
    align-content: center;
    align-items: center;
}

.datatableHeader {
    background: rgb(65 65 65 / 47%);
    border-radius: 0rem;
    z-index: 5;
    padding: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

@media screen and (max-height: 800px) {
    .datatableHeader {
        gap: 0.5rem;
        padding: 0 0 0 0.5rem;
    }
}



.datatableHeaderFilterRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;

    gap: 1rem;
}

.PayrollUploaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    border-radius: 8px;
    border: 2px solid rgb(70, 70, 70);
    padding: 8px 16px;
    margin: 0px;
    background: #2b2b2b;
    gap: 1rem;
    box-shadow: inset 0px 0px 10px 0px #00000061;
}

.CommitButtonContainer {
    margin-left: auto;
    margin-right: 10px;
    height: 100%;
    width: auto;
}



.paymentTabButtonGroup {
    box-shadow: 0px -4px 10px 4px rgb(25 25 25 / 36%) !important;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    background: rgba(88, 88, 100, 0.45);
    border-radius: 8px 8px 0px 0px;
}

.paymentTabButton {
    padding: 12px 15px !important;
    border-radius: 0px !important;
    background: transparent !important;
    border-bottom: 2px solid lightblue !important;
    border-bottom: 2px solid rgba(3, 140, 253, 0.678) !important;
    transition: all 0.35s ease-in-out !important;
}

.paymentTabButton:hover {
    outline: none !important;
    color: rgb(3, 140, 253) !important;
    border: none;
    border-bottom: 2px solid rgba(3, 140, 253, 0.678) !important;
    box-shadow: inset 0px -20px 12px -15px rgb(68 134 203 / 10%) !important;
}

.paymentTabButtonActive {
    position: relative;
    outline: none !important;
    color: rgb(3, 140, 253) !important;
    border: none;
    border-bottom: 2px solid rgba(3, 140, 253, 0.678) !important;

}

.paymentTabButtonActive::before {
    content: '';
    position: absolute;
    background: rgb(100, 164, 216);
    bottom: -2px;
    left: 0px;
    width: 100%;
    height: 2px;
    box-shadow: 0px 0px 12px 2px rgb(0 107 255 / 90%) !important;
    border-radius: 20px;
    z-index: 5;
}

.PipelineFilterRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: auto;
    height: 100%;
    padding: 10px;

    margin-inline-start: auto;
    background: rgb(65 65 65 / 47%);
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.5);

}

@media screen and (max-height: 800px) {
    .PipelineFilterRow {
        border-radius: 0rem 0.5rem 0.5rem 0rem;
    }
}


.CustomFilterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    position: relative !important;
    max-width: 405px;


}

.dataTableHeaderButtonGroup {
    margin: 5px 0px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.dataTableHeaderBottom {

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;


}

.dataTableContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
}


.maindataTableFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5rem;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background: rgb(65 65 65 / 47%);
    background: var(--color-dark1); /* #2f2f2f */

    z-index: 5;
}

.dataTableFooterLoadingBar {
    width: 15%;
    padding-inline: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-datatable-wrapper {
    width: 100%;
    height: 100%;

    padding: 0px;
    margin: 0px;

    overflow: scroll;
    background: transparent;

}

#divTable .p-datatable-header {
    padding: 0 !important;
}

#divTable .p-datatable-footer {
    padding: 0 !important;
}

.p-datatable .p-column-header-content {
    display: flex;
    flex-direction: column;
}

.LoadingContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 15;

}

.LoadingBackgroundBlur {
    width: 100vw;
    height: 100vh;
    position: absolute;

    z-index: 13;
    background: rgba(46, 46, 46, 0.726);
    backdrop-filter: blur(2px);
}

.defaultRow {
    box-shadow: inset 0px 20px 20px 11px rgb(88 88 88 / 10%) !important;

    transition: all 0.15s ease-in-out !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody .defaultRow:hover {
    background: linear-gradient(0deg, rgb(22 27 35), rgb(44 62 89)) !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody .defaultRow:focus {

    background: linear-gradient(0deg, rgb(22 27 35), rgb(44 62 89)) !important;
}

.p-highlight {
    background: linear-gradient(0deg, rgba(23, 70, 114, 0.69), rgb(37 111 179 / 69%)) !important;
    color: white !important;
    box-shadow: none !important;
}

.p-highlight:hover {
    box-shadow: none !important;
    background: linear-gradient(0deg, #0045a5d2, rgba(37, 110, 179, 0.815)) !important;
    color: white !important;
}



.dataTableNewMessageRow {
    background: #1e1e1e !important;
    font-weight: bold;


}

.dataTableRejectedRow {
    background: #ff00003a !important;
    transition: all 0.25s ease-out !important;
}




.RowPriceText {
    font-size: 1rem;
    text-align: left;
    color: rgb(139, 223, 106);
    text-shadow: 0px 0px 4px rgba(91, 150, 70, 0.75);
    justify-content: flex-start;
    width: 120px;
}

.GOPHeaderColumn {
    font-size: 1rem;
    text-align: left;
    justify-content: flex-start;
    width: 120px;
    grid-row-start: 3;
}

.GOPPriceRowGroup {
    display: grid;
    grid-template-columns: max-content 0.2fr max-content 0.2fr max-content;
    grid-template-rows: max-content 0.2fr max-content;
    column-gap: 8px;
    justify-items: start;
    align-items: center;
    justify-content: start;
    align-content: center;
}

.GOPPriceRowGroupHeader {
    display: grid;
    grid-template-columns: max-content auto max-content auto max-content;
    grid-template-rows: max-content max-content;
    column-gap: 10px;
    justify-items: center;
    align-items: center;
    justify-content: start;
    align-content: center;
}


.RowCountText {
    font-size: 1rem;
    text-align: right;
    justify-content: flex-end;
    color: rgb(255, 255, 255);
}




.FormDialog {

    height: 90%;

    width: 70%;
    background: rgba(125, 125, 125, 0.05);
    backdrop-filter: blur(12px);
    border: 2px solid #000;
    box-shadow: 24;
    padding: 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 15fr 2fr;

    gap: '0px 0px';
}



.LoginPageContainer {
    background: linear-gradient(to top, rgb(37 38 40) 0%, rgb(43 45 49) 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
}


.LoginBox {
    position: relative;
    width: 50%;
    max-height: 60%;
    border-radius: 12px;
    border: 1px solid rgba(125, 188, 255, 0.05);
    background: rgba(11, 18, 30, 0.25);
    padding-top: 3rem;
    box-shadow: 1px 6px 40px 16px rgba(26, 26, 26, 0.4);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    min-height: 400px;
    max-width: 1000px;
    min-width: 400px;
}

.LoginTopContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    width: 100%;
    padding-inline: clamp(1rem, 8%, 8rem);
    height: 250px;
}

.LoginLogoContainer{

        position: absolute;
        padding: 4px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        bottom: 16px;
        right: 16px;

}


.modal-container {
    overflow: auto;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    box-shadow:
        1px 1px 0px hsla(0, 0%, 55%, 0.5),
        2px 2px 0px hsla(0, 0%, 45%, 0.5),
        3px 3px 0px hsla(0, 0%, 32%, 0.7),
        4px 4px 0px hsla(0, 0%, 20%, 0.6),
        5px 5px 0px hsla(0, 0%, 16%, 0.5),
        6px 6px 0px hsla(0, 0%, 12%, 0.4),
        7px 7px 10px hsla(0, 0%, 8%, 0.3);
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0px;
    background: rgb(77, 77, 77);
    padding: 24px !important;

    height: 100vh;

    max-height: 100vh;
    min-width: 500px;
    min-height: 700px;
    transition: all 0.13s ease-in !important;

}


.AutoAddButtonGroup {
    padding: 20px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;


}

.form-container {

    background: linear-gradient(to top, hsl(224, 12%, 22%) 0%, hsl(224, 12%, 32%) 60%);
    background: rgb(44, 44, 44);
    border-radius: 12px;
    box-shadow:
        inset 1px 1px 0px hsl(224, 12%, 28%),
        inset 2px 2px 0px hsl(224, 12%, 25%),
        inset 3px 3px 0px hsl(224, 12%, 22%),
        inset 4px 4px 0px hsl(224, 12%, 18%),
        inset 5px 5px 0px hsl(224, 12%, 16%),
        0px 0px 25px 8px hsla(0, 0%, 55%, 0.32);

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: 100%;
    flex-grow: 1;
    border: 2px solid hsla(0, 0%, 60%, 0.14);
    overflow-y: auto;

}

.form-container::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

.form-container::-webkit-scrollbar-thumb {
    background-color: rgb(66, 66, 66);
    /* Set the color of the thumb */
}

.form-container::-webkit-scrollbar-track {
    background-color: rgb(19, 19, 19);
    /* Set the color of the track */
    border-left: 2px solid black;
    border-radius: 0px 24px 0px 0px;

}


.form-container::-webkit-scrollbar-thumb {
    padding-top: 5px;
    border-radius: 0px 2px 2px 0px;
    background: linear-gradient(90deg, rgb(55, 55, 55), rgb(90, 90, 90))
}


.PatientCaseInfoEditForm {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    flex-wrap: wrap;
    width: auto;
}
.PatientCaseInfoCreateForm{
    display: flex;
    flex-direction: column;

    flex-wrap: nowrap;
    width: 100%;

}

.formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 30px 8px 30px;
    width: 100%;
    font-size: 1.2rem;

}

.patientInfoHeader {
    background: hsl(224, 12%, 22%);
    height: 44px;
    padding: 0px 0px 0px 16px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid rgba(109, 109, 109, 0.5);
}



.formHalfSection {
    padding: 10px 30px;


}

.formGroup {
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.25);

    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

}

.CustomListItemRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 30px 8px 30px;
    width: 100%;

    margin: 0px;
    padding: 5px 10px 5px 10px;
    border-bottom: 1px solid rgba(109, 109, 109, 0.3);
    box-shadow: inset 1px 0px 8px 2px rgba(0, 0, 0, 0.2);
}
.hospitalCaseSelection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px -4px 16px -4px rgba(5, 5, 5, 0.25) !important;
    background: red;
}
.hospitalCaseSelection:hover{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.hospitalCaseSelectionActive{
    background: rgba(170, 170, 170, 0.1) !important;
    border: 1px solid rgba(0, 0, 0, 0.25);

}




.formUploadTitleRow {
    margin: 0px;
    width: 100%;
    box-shadow: inset 1px 0px 8px 2px rgba(0, 0, 0, 0.45);
    background: rgb(44, 44, 44);
    height: auto;
    padding: 0px 0px 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.formUploadButton {


    border-radius: 0px !important;
    border-left: 2px #000000d1 solid !important;
    box-shadow: -6px 0px 10px -6px #1e1e1e !important;
    padding: 4px 10px 4px 10px !important;
    min-width: 125px !important;
}

.formUploadButton:hover {
    background: rgb(88, 123, 177);
    outline: none !important;
    box-shadow: none !important;
    -webkit-filter: brightness(1.25) saturate(1.25);
    filter: brightness(1.25) saturate(1.25);
}

.formUploadProgressBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50% !important;
    padding-inline: 1rem !important;
}

.formUploadTextSpan {
    width: 100%;
    letter-spacing: 0.00938em;
    align-items: flex-start;
    text-align: flex-start;
    margin-right: 10px;
    color: rgb(193, 216, 240);
    font-weight: bold;
    font-size: 15px;
    padding: 5px 0px;
}


.fileLinks {
    display: list-item;

}

.fileLinks::marker {
    content: '•  ';
    font-size: 1rem;

}

.newMessageFlag {

}

@keyframes rotateLeft {

    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 100% 50%;
    }

    10% {
        transform: rotate(2deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-4deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(4deg);
    }

    80% {
        transform: rotate(-2deg);
    }

    90% {
        transform: rotate(2deg);
    }
}



.InfoListContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: rgba(131, 131, 131, 0.2);
    border: 1px solid rgba(22, 22, 22, 0.75);
    border-right: 1px solid rgb(30, 30, 35);
    border-left: 0px;
    box-shadow: inset 0px 0px 10px 0px rgba(20, 20, 20, 0.75), 0px 0px 10px 0px rgba(20, 20, 20, 0.25);

}

.ActivityLogNavBar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    width: 100%;
    padding: 0px;
    margin-bottom: 8px;
    border-bottom: 2px solid rgba(183, 190, 196, 0.9);
}

.ActivityLogNavBarBottom{

    width: 100%;
    background: rgba(80, 83, 99, 0.5);
    padding: 0px 12px 8px 12px;

}



.ActivityLogNavBarTop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background: rgba(80, 83, 99, 0.45);

    box-shadow: inset 0px 0px 10px 0px rgba(20, 20, 20, 0.15);
    padding: 8px 12px;
}





.ActivityLogNavBarButtonGroup{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
}

.InfoActivityLogSection{
    background: rgb(39, 39, 39);
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow-y: auto;
    border: 2px solid rgba(22, 22, 22, 0.5);
    box-shadow: inset 0px 0px 10px 0px rgba(20, 20, 20, 0.45);
    min-height: 400px;
}
.ActivityLogItemContainer{
    background: rgb(73, 73, 73);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 12px 24px 12px;
    width: 100%;
    border: 1px solid rgba(22, 22, 22, 0.3);
    gap: 0.4rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.35);
    box-shadow: -3px 3px 6px 0px rgba(20, 20, 20 ,0.33);
}
.ActivityLogItemContainerOperator{
    border-left: 6px solid rgba(0, 117, 252, 0.699);
}
.ActivityLogItemContainerOperator:hover{
    background: rgba(0, 117, 252, 0.2);
}
.ActivityLogItemContainerClient{
    border-left: 6px solid rgba(0, 252, 0, 0.699);
}
.ActivityLogItemContainerClient:hover{
    background: rgba(0, 252, 0, 0.2);
}



.ActivityLogItemContainer:hover{

    box-shadow: inset 0px 0px 10px 0px rgba(20, 20, 20, 0.2);
    transition: all 0.25s ease-in-out;
}

.ProformaPageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    padding: 20px 25px;
}

.ProformaCreatorContainer {
    margin: 5px 30px 0px 0px;
    padding: 5px 0px;
    min-width: 500px;
    max-width: 600px;
    width: 100%;
    border-radius: 26px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 90vh;
    overflow: auto;


    margin-top: 4;
    background: rgba(77, 77, 77, 0.15);

    border: 1px solid rgba(125, 188, 255, 0.5);
    box-shadow: 0px 0px 12px 0px black;


}

.TotalCounterContainer {
    border-top: 2px solid rgba(125, 188, 255, 0.5);
    background: linear-gradient(to top, hsl(224, 12%, 22%) 0%, hsl(224, 12%, 32%) 60%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 20px;
    align-items: flex-end;

}

.PatientInfo-container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    max-width: 600px;
}

.infoSection {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    background: rgb(32, 32, 32);
    box-shadow: inset 0px 0px 10px 0px rgba(20, 20, 20, 0.75), 0px 0px 10px 0px rgba(20, 20, 20, 0.25);
    overflow-y: auto;
}

.hospitalCaseSection {
    width: 100%;
    padding: 0px;
    background: rgba(44, 44, 44, 0.75);

}

.hospitalCaseSectionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    height: 40px;
    width: 100%;
    background: linear-gradient(to top, hsl(224, 12%, 16%) 0%, hsl(224, 12%, 22%) 80%);

}

.patientInfoCaseTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px;
    gap: 0.5rem;
    width: 100%;
    min-width: 140px;
}

.hospitalAddRemButtonGroup {

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;
    height: 100%;
    padding: 10px 0px;

}

.DocumentsPageContainer {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 0px;
    height: 100%;
    flex-grow: 2;
    justify-content: flex-start;


}


.DocumentsTableHalfSection {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.DocumentsTableRow {
    background: rgba(44, 44, 44, 0.35);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: #000 solid 2px;
    flex-grow: 1;
    max-height: 36px;
    width: 100%;
    padding: 2px 20px 6px 10px;
}

.DocumentsTableContainer {
    flex-grow: 2;
    height: 50%;
    background: rgba(44, 44, 44, 0.35);
    border: #000 solid 2px;
}


.formTextField {
    width: 75%;

}

.TreatmentListBox {
    width: 100%;
    margin: 24px 0px 0px 0px;
    padding: 0px 15px;
}



.TreatmentListBoxHeader {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: linear-gradient(to top, hsl(224, 12%, 11%) 0%, hsl(224, 12%, 22%) 30%);
    padding: 4px;
    border: 2px solid rgba(34, 34, 37, 0.9);
    border-bottom: 2px solid hsl(224, 12%, 11%);



}


.TreatmentElement {
    background: linear-gradient(to top, hsl(224, 12%, 22%) 0%, hsl(224, 12%, 32%) 60%);
    border-radius: 2px;
    gap: 0.5rem;
    border: 2px solid rgba(34, 34, 37, 0.9);
    border-top: 0px;
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
}


.TreatmentElementRejected {
    background: linear-gradient(to top, hsl(0, 25%, 22%) 0%, hsl(0, 35%, 32%) 60%);
    ;

}

.TreatmentElementApproved {
    background: linear-gradient(to top, hsl(125, 25%, 22%) 0%, hsl(125, 35%, 32%) 60%);
    ;
}

.TreatmentControlContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 1366px) {
    .TreatmentControlContainer {
        gap: 0.5rem;
    }
}

.TreatmentAmountBox {
    background: rgb(83, 83, 83);

    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    outline: 2px solid rgba(0, 0, 0, 0.178);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.5), 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.TreatmentPriceBox {
    width: 70px;
    display: flex;
    justify-content: flex-end;

}

.progressBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 24px;
    background: transparent;
    border-radius: 30px;

}

.progressBarSegment {
    position: relative;
    height: 100%;
    width: 25%;
    background: gray;
    border: solid rgba(255, 255, 255, 0.33) 1px;
    display: flex;
    justify-content: center;

}

.progressBarButton {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: #ff9900;


    border-radius: 0px !important;
    height: 30px;
    margin: 0;
    width: 100%;
    padding: 0;





}


.progressBarButtonIcon {

    position: relative;
    font-size: 0.75rem;
}

.ReviewPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;

}

.ReviewPageListContainer {
    height: 100%;
    width: 400px;
}
.ReviewPageFormContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    background: var(--background-gradient-d);
    box-shadow: inset 0px 0px 24px 8px rgba(0, 0, 0, 0.25), 0px 0px 12px 4px rgba(0, 0, 0, 0.5);
    border-top: 2px solid var(--color-main-theme-dark-desaturated);
    border-bottom: 2px solid var(--color-main-theme-dark-desaturated);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.formRowReview {
    padding: 10px 30px;
    width: 100%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/*





*/



.HeaderUnderlined {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;


    height: 60px !important;
    min-height: 60px !important;
    padding: 12px 5px 12px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-bottom: 2px solid rgba(109, 109, 109, 0.5);
    background: hsl(224, 12%, 22%);


    background: #16181d;
    box-shadow: inset 0px -20px 16px -20px rgb(74 159 239 / 50%), inset 0px 3px 14px 0px #01010140;
    border-radius: 0;

    border-bottom: 1px solid rgb(3, 140, 253);

}

/*

  display: flex;
  flex-direction: row;
  width: 100%;
  margin-inline: auto;
  justify-content: center;
 padding: 0rem 3.25rem 0rem 1rem;

  background: #16181d;
  box-shadow: inset 0px -20px 16px -20px rgb(74 159 239 / 50%), inset 0px 3px 14px 0px #01010140;
  border-radius: 0;

  border-bottom: 4px solid rgb(3, 140, 253);



*/


.LangSelect #langselect {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 0.5rem 0rem 0.5rem 0.75rem !important;

}



.flagContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-block: 10px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
}


.commentsInput {
    display: flex;
    width: 100%;
    min-width: 35rem;
    height: 100%;
    max-height: 120px;
    padding: 10px 10px 10px 10px;

    background: rgba(44, 44, 44, 0.35);
    border: #00000096 solid 2px;
    border-top: none;
    box-shadow: inset 0px 8px 24px 2px #3f3f3fb7, 0px 8px 10px 4px #171717ea;
    overflow: hidden;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: flex-end;
    gap: 10px;
}

.commentsRender {
    overflow-y: auto;
    height: 100%;
}

.commentsDisplay {
    position: relative;
    width: 100%;
    min-width: 35rem;
    height: 100%;
    min-height: 30vh;
    padding: 20px;

    background-image: linear-gradient(0deg, #26282d40, #151515d4);
    border: #00000096 solid 2px;
    border-top: #000000 solid 2px;
    box-shadow: inset 0px 8px 24px 2px #2d2d2db7, 0px 0px 12px 4px #171717ea;
    border-radius: 20px 20px 0px 0px;
    overflow-y: auto;
    /*
    background-size: 100% 400%;
    background-position: 50% 0%;
    transition: all 0.25s ease-in-out;
*/
}

.commentsDisplay::-webkit-scrollbar {
    width: 12px;
    background: transparent;
}

.commentsDisplay::-webkit-scrollbar-thumb {
    background-color: rgb(66, 66, 66);
}

.commentsDisplay::-webkit-scrollbar-track {
    background-color: rgb(19, 19, 19);
    border-left: 2px solid black;
    border-radius: 0px 24px 0px 0px;
}

.commentsDisplay::-webkit-scrollbar-thumb {
    padding-top: 5px;
    border-radius: 0px 24px 0px 0px;
    background: linear-gradient(270deg, rgb(55, 55, 55), rgb(100, 100, 100));
}



/*
.commentsDisplay::before {

    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;

    width: 100%;

    box-shadow: 0px 0px 0px 0px #8cc2e257;
    z-index: 5;

    transition: box-shadow 1s ease, height 0.25s ease;

     background: linear-gradient(315deg, #494949 -15%, #33373a 25%, #252222 85%, #6a6a6a 120%);
}
*/
.commentsContainer:hover .commentsDisplay {

    background-size: 50% 100%;
}

.commentsContainer:hover .commentsDisplay::before {

    box-shadow: 0px 0px 128px 48px #72a7c57a;
}


.commentsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    height: 100%;
    padding-top: 30px;
    padding-inline: 30px;
    margin: 0px;
    border-left: #191919 solid 2px;

    padding-bottom: 4rem;

}

.commentTextContainer {
    max-width: 100%;
    word-wrap: break-word;
}

.commentTextContainer .MuiTypography-root {
    word-wrap: break-word;
    line-break: anywhere;
}


.commentElement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: fit-content;

    border-radius: 10px;
    padding: 10px 20px;
    max-width: 95%;
    min-width: 100px;
    margin: 10px 0px;
    position: relative;
}

.commentElementOperator {
    background: linear-gradient(to top, hsla(224, 13%, 17%, 0.541) 0%, hsla(224, 21%, 34%, 0.301) 75%);
    box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.5);
    margin-top: 0px;
    backdrop-filter: blur(8px);
}

.commentElementUser {
    margin-top: 0px;
    background: linear-gradient(to top, hsla(128, 33%, 22%, 0.336) 0%, hsla(128, 44%, 29%, 0.548) 75%);
    box-shadow: 0px 0px 16px -2px rgb(12, 12, 12);
    backdrop-filter: blur(8px);
}

.commentElementYou {
    justify-content: flex-start;
    border-radius: 10px 10px 10px 0px;
    padding-right: 50px;

}

.commentElementYou .MuiTypography-root {
    justify-content: flex-end;
}


.commentElementMe {
    justify-content: flex-end;
    border-radius: 10px 10px 0px 10px;
    padding-left: 50px;

}


.commentElementMe .MuiTypography-root {
    justify-content: flex-end;
}







.commentRow {
    display: flex;
    flex-direction: column;

    flex-wrap: nowrap;
    width: 100%;
    height: auto;
}

.commentRowMe {
    justify-content: flex-end;
    align-items: flex-end;
}

.commentRowMe .DividerStampContainer {
    margin-bottom: 0.5rem;
    justify-content: flex-end;
    align-items: flex-end;
}


.commentRowYou {
    justify-content: flex-start;
    align-items: flex-start;
}

.commentRowYou .DividerStampContainer {

    margin-bottom: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
}
.DividerStampMe{
    flex-direction: row-reverse !important;
    justify-content: flex-start !important;
}
.DividerStampYou{
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
}



.commentDateStamp {
    /*
  position: absolute;
  bottom: 0px;
  right: 0px;
*/
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: flex-end;
    flex-wrap: nowrap;
    flex-flow: row wrap;
    padding: 0px 10px !important;

}


.mailSelectorElement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-inline: 10px;
    width: 100%;
    height: 35px;
    background: rgba(44, 44, 44, 0.35);

    box-shadow: inset 0px 8px 24px 2px #3f3f3fb7;
    overflow: hidden;
    flex-wrap: nowrap;

    border-radius: 0px;


}





.DividerStampContainer {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    width: clamp(220px, 100%, 500px);


}

.GOPDocumentPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: flex-start;
}

.GOPDocumentPageInputWrapper {
    display: flex;

    padding: 2rem;
    gap: 1rem;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
    background: rgb(51 54 57);
    box-shadow: inset 0px 0px 20px 2px rgb(0 0 0 / 53%), inset 0px -20px 16px -20px rgb(74 159 239 / 50%);
    border-bottom: 2px solid rgb(74 159 239 / 57%);
}

.GOPDocumentPageInputContainer {
    display: flex;
    padding: 0px 10px 20px 10px;
    gap: 1rem;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;


}
.GOPDocumentPageInputContainerMain{
    display: flex;
    padding: 10px 30px;
    gap: 8px;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    background: rgb(34, 35, 36);
    border-radius: 8px;
    border: 2px solid rgb(78, 78, 78);

}
.GOPDocumentPageInputContainerTop{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0px 20px;
}

.GOPInputFormRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0px;
    width: 160px;


}

.ProformapdfContainer {


    padding: 0;
    flex-grow: 1;
    width: 50vw !important;
    height: 82vh !important;
    outline: 2px solid rgba(48, 48, 48, 0.9);

}

.DocsButtonControlsGroup {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 55px;
    margin-inline: auto;
    justify-content: center;
    background: #16181d;
    box-shadow: inset 0px -20px 16px -20px rgb(74 159 239 / 50%), inset 0px 3px 14px 0px #01010140;
    border-radius: 0;

    border-bottom: 4px solid rgb(3, 140, 253);
    padding: 0rem 3.25rem 0rem 1rem;
    gap:4rem;

}

.DocsPageSwitchContainer{
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
}


.docspdfContainer {
    padding: 0;
    flex-grow: 1;
    height: 100% !important;
    background: rgb(44, 44, 44);

}

.HorizontalpdfContainer {
    background: rgb(44, 44, 44);
    flex-grow: 1;
    width: 50vw !important;
    height: 100%;
}

.GOPpdfContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background: rgb(44, 44, 44);
}

.progressHeaderDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100% !important;
}

.progressHeaderSpan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25%;
    border-right: 1px solid rgba(255, 255, 255, 0.33);
    position: relative;

}


#divTable {
    display: flex !important;
    justify-content: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: nowrap !important;
    flex-direction: column !important;
    align-items: stretch !important;
}

/*
.p-datatable-thead {
    z-index: 31 !important;

}

*/
.p-selection-column .p-checkbox {

    color: rgb(245, 245, 245);
    background: rgb(9, 255, 0);
}

.p-column-title {

    width: 100%;
    display: flex;
    flex-direction: row;
}

.p-column-header-content {
    width: 100%;
    display: flex;
    flex-direction: row !important;

}


#divTable>div.p-datatable-wrapper>table>tbody>tr {
    height: 50px;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(1)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(2)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(3)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(1)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(2)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(3)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(4)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

#divTable>div.p-datatable-wrapper>table>thead>tr:nth-child(2)>th:nth-child(4)>div>button.p-column-filter-menu-button.p-link {
    display: none !important;
}

body>div.MuiModal-root.css-79ws1d-MuiModal-root>div.modal-container.MuiBox-root.css-1buvf4e>button {
    z-index: 50;

}






.paymentListCreatorContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
}

.paymentListCreatorHeaderBottom {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-inline: 1rem 4rem;
    max-height: 40px;
    padding-block: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(35, 52, 77);
}

.paymentListCreatorSliderContainer {

    width: 75%;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
}

.paymentListCreatorHeaderTop {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-inline: 4rem;
    max-height: 40px;
    padding-block: 2rem;


    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(35, 52, 77);
}

.paymentListCreatorHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);

}

.paymentListCreatorHospitalElement {
    width: 100%;
    justify-content: space-between;
    background-color: #27292b;
    padding-inline: 1rem 0rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: row;
    border-bottom: 2px solid rgba(165, 165, 165, 0.3);
}

.paymentListCreatorHospitalElementTableInfo {
    display: grid;
    max-width: 20rem;
    grid-template-columns: 1fr 1fr;

    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-inline: 2rem 0rem;


}

.paymentListHeaderWrapper {
    background-color: rgba(10, 10, 10, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.6);
}

.breadcrumbWrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-inline: 1rem;
    padding-block: 0.15rem;
    justify-content: space-around;
    align-items: center;
}



.breadcrumbPaymentContainer {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.NavBarBackground{
    display: flex;
    flex-direction: row;
    background: var(--color-dark-tint);
    height: 100%;

}

.NavBarBottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid rgb(90, 90, 90);
}

.NavBarButtonContainerVertical{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 4px;
}
.NavBarButtonContainerHorizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;

}



.mailWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0px;

    background: rgb(44, 44, 44);

    box-shadow: inset 0px 0px 18px 4px rgba(0, 0, 0, 0.25);

    overflow-y: auto;
    position: relative;

}

.mailContainer {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;

    padding: 20px;

    background: rgb(39, 39, 47);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.55);
    border-radius: 8px;
    overflow-y: auto;
    max-height: 40vh;
}

.mailSelectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    max-height: 140px;
    overflow-y: auto;

}


.disableResponse>p:last-child {
    display: none;
}

#mailContent>p {
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-weight: bold;

    line-height: 1.5rem;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;


}

.mailSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 50vw;
    max-width: 50vw;
    height: 100%;
    padding: 0px;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;
}

.p-contextmenu{
    z-index: 2000 !important;
}





.dashboardPageContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    background: rgb(31, 31, 31);
    box-shadow: inset 0px 0px 18px 4px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    position: relative;
    gap: 2.5rem;



}

.dashboardCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    background: rgb(49, 50, 54);
    padding: 10px;
    border-radius: 6px;
    box-shadow: inset 0px 0px 36px 4px rgba(0, 0, 0, 0.25), 0px 0px 36px 2px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.05);


}
.dashboardRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    gap: 2.5rem;

    padding-block: 2rem;

}



.dashboardGrid{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(500px, auto) 1fr;
    grid-gap: 1.5rem;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    justify-items: stretch;
    position: relative;

}


.dashboardCardGrid{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, minmax(40px, 50px));
    gap: 4px;
    width: 100%;

    height: 100%;
    background: rgb(49, 50, 54);
    border-radius: 6px;
    box-shadow: inset 0px 0px 36px 4px rgba(0, 0, 0, 0.25), 0px 0px 36px 2px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.05);


}



.dashboardProgressLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 12px;

}

.dashboardBottomGrid{
    display: grid;
    grid-template-columns: minmax(600px,2fr) minmax(400px, 3fr);
    grid-template-rows:  minmax(100px, 400px);
    gap: 1.5rem;
    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
}


.ChatPageContainer{
    display: grid;
    grid-template-columns: minmax(400px, 1fr) minmax(720px, 4fr) minmax(400px, 1fr) ;
    grid-template-rows: minmax(0, 1fr);
    width: 100%;
    height: 100%;
    padding: 0px;

    background: rgb(53, 53, 53);
    box-shadow: inset 0px 0px 18px 4px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    position: relative;

}

.ChatSelectionTable{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 0px;
    overflow-y: auto;


}
.ChatButtonRow{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 0.2rem;

}
.ChatButtonLeft{

    max-width: calc(100% - 100px);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
    padding-inline: 1rem;


}
.ChatButtonRight{
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 8px;


}

.ChatTableHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline: 2rem;
    gap: 1rem;
    background: rgb(31, 31, 31);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-block: 1rem;
}

.commentIconContainer{
    position: absolute;
    left: 8px;
    bottom: 4px;
}



.CaseSearchAutocomplete {
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    font-size: 1rem;
    background-color: #575454;
}
.MuiAutocomplete-noOptions {
    background-color:  var(--color-dark1);
    color: var(--color-text-secondary);
}


.whatsAppImage{
    width: 100%;
    height: auto;
    max-width: 100px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.whatsAppImageHover{
    pointer-events: none;
    position: absolute;
    width: 250px;
    height: auto;
    z-index: 1000;
    border: 1px solid rgba(0, 255, 76, 0.2);

}



.InsuranceHeaderContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-inline: 1rem;
    gap: 1rem;
    background: rgb(31, 31, 31);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-block: 1rem;
}
.InsuranceUploadButtonGroup{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1rem;
    height: 100%;
    background: rgb(31, 31, 31);

}

.searchDrawer {

    transition: height 0.3s ease;
    background: #42424b;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #b6b6bc;
    border-top: none;
}

.searchDrawerOpen {
    height: 200px; /* Adjust this height as needed */
}

.PolicyValidTextContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    gap: 1rem;
    background: rgba(190, 190, 190, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.35);
}


.userDataContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 0px;
    overflow-y: auto;
    gap: 1rem;
}
.userDataRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding-inline: 1rem;
    gap: 1rem;
    background: rgb(31, 31, 31);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-block: 1rem;
}



.miniGraphTooltip{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 6.5rem 1rem 1rem 1rem;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.8rem;
    font-weight: bold;
    z-index: 1000;
    backdrop-filter: blur(4px);
}

.miniGraphTooltipTextContainer{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    background: #333333;
    border: 1px solid rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

}

.miniGraphTooltipTextLine{
    padding: 4px 12px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);

}
.miniGraphTooltipMarker{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #ff9900;
}